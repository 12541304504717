import React, { FC, ReactNode } from 'react'
import Header from './Header'
import Footer from './Footer'

interface Props {
    children: ReactNode
}

const Layout: FC<Props> = ({ children }) => {
    return (
        <div className='layout'>
            <Header />
            <main className='mainLayout'>{children}</main>
            <Footer />
        </div>
    )
}

export default Layout