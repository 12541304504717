import moment from "moment";

export const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (!/[0-9]/.test(e.key)) {
    e.preventDefault();
  }
};

export const calculateAge = (dob: string): number => {
  const birthDate = moment(dob, "DD-MM-YYYY");
  const currentDate = moment();
  return currentDate.diff(birthDate, "years");
};
