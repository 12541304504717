import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Layout from './components/layout';
import Login from './pages/Login';
import Prescription from './pages/prescription';
import { useEffect } from 'react';

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const emailParam = params.get('email');
    const tokenParam = params.get('token');
    const cidParam = params.get('cid');
    if (emailParam) {
      navigate('/', {
        state: {
          emailParam: emailParam,
          tokenParam: tokenParam,
          cidParam: cidParam
        }
      })
    }
    else if (location.state && location.state.emailParam) {
      navigate('/', {
        state: location.state,
        replace: true
      })
    } else {
      navigate('/login')
    }
  }, [])

  return (
    <Layout>
      <Routes>
        <Route path='/' element={<Prescription />} />
        <Route path='/login' element={<Login onLogin={() => navigate('/')} />} />
      </Routes>
    </Layout>
  );
};

export default App;