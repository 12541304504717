import React from 'react'
import styles from "../../assets/styles/layout.module.css"
import logo from "../../assets/images/logo.png"
import CustomContainer from '../common/CustomContainer'
import { useLocation, useNavigate } from 'react-router-dom'
const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleLogout = () => {
        if (window.confirm("Are you sure you want to logout?")) {
            navigate('/login');
        }
    }
    return (
        <header className={styles.header}>
            <CustomContainer>
                <div className={styles.headerMain}>
                    <div className={styles.logo}>
                        <img src={logo} alt="logo" />
                    </div>
                    {
                        location.pathname === '/' &&
                        <div className={styles.logout}>
                            <button className='outLineBtn' onClick={handleLogout}>Logout</button>
                        </div>
                    }
                </div>
            </CustomContainer>
        </header>
    )
}

export default Header