import axios from "axios";
import {
  BASE_URL,
  DOCTOR_LOGIN,
  GET_MEDICINES,
  GET_PRESCRIPTION,
  SAVE_MEDICINE,
  SAVE_PRESCRIPTION,
} from "./routes";

axios.defaults.baseURL = BASE_URL;
interface ApiResponse<T> {
  success: boolean;
  message: string;
  data: T;
  [key: string]: any;
}
const handleApiError = (error: any) => {
  if (error.response) {
    throw {
      status: error.response.status,
      message: error.response.data.message || "An error occurred",
      data: error.response.data,
    };
  } else if (error.request) {
    throw {
      message:
        "No response received from server. Please check your network connection.",
    };
  } else {
    throw {
      message: error.message,
    };
  }
};

export const getPrescriptionApi = async (
  data?: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axios.get(GET_PRESCRIPTION, {
      params: data,
    });
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const savePrescriptionApi = async (
  data?: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axios.post(SAVE_PRESCRIPTION, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const saveMedicineApi = async (
  data?: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axios.post(SAVE_MEDICINE, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getMedicineApi = async (data?: {
  search: string;
}): Promise<ApiResponse<any>> => {
  try {
    const response = await axios.get(GET_MEDICINES, {
      params: data,
    });
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const doctorLoginApi = async (data: any): Promise<ApiResponse<any>> => {
  const doctorLoginAxios = axios.create({
    baseURL: "https://connect-api.akosmd.in/api", 
  });
  try {
    const response = await doctorLoginAxios.post(DOCTOR_LOGIN, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
