import React, { FC, ReactNode } from 'react'
interface Props{
    children: ReactNode
}
const CustomContainer:FC<Props> = ({children}) => {
  return (
    <div className='customContainer'>
        {children}
    </div>
  )
}

export default CustomContainer