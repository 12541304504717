import React, { FormEvent, useEffect, useRef, useState } from 'react';
import styles from "../assets/styles/prescription.module.css";
import CustomContainer from '../components/common/CustomContainer';
import { FormGroup, FormLabel, InputAdornment, MenuItem, Select, SelectChangeEvent, TextareaAutosize, TextField } from '@mui/material';
import MedicineTable from '../components/common/MedicineTable';
import MedicineDetails from '../components/prescription/MedicineDetails';
import SignatureCanvas from 'react-signature-canvas'
import { getPrescriptionApi, savePrescriptionApi } from '../utils/services';
import { toast } from 'react-toastify';
import Loader from '../components/common/Loader';
import { calculateAge, handleKeyPress } from '../utils/functions';
import BtnLoader from '../components/common/BtnLoader';
import { useLocation } from 'react-router-dom';

const Prescription: React.FC = () => {
    const [prescriptionData, setPrescriptionData] = useState<PresResponse | null>(null)
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [btnLoading, setBtnLoading] = useState<boolean>(false);
    const [loading, setLoading] = useState(false)
    const [medicines, setMedicines] = useState<Medicine[]>([]);
    const [selectedMedicine, setSelectedMedicine] = useState<Medicine | null>(null);
    const [isDisabled, setIsDisabled] = useState(false)
    const [formState, setFormState] = useState({
        patientName: '',
        email: '',
        phone: '',
        age: '',
        height: '',
        weight: '',
        gender: '',
        location: '',
        chiefComplaints: '',
        diagnosis: '',
        previousHistory: '',
        labFindings: '',
        suggestedInvestigations: '',
        specialInstructions: '',
        signature: '',
    })
    const [DoctorData, setDoctorData] = useState<DoctorInfoPres | null>(null)
    const params = new URLSearchParams(window.location.search).get('email');
    const location = useLocation();

    // signature start

    const signatureRef = useRef<any>(null);

    const handleSignatureChange = () => {
        if (signatureRef.current) {
            const signatureImage = signatureRef.current.toDataURL(); // Get the signature as a base64-encoded string
            setFormState({ ...formState, signature: signatureImage }); // Update the formState with the signature image
        }
    };
    const clearSignature = () => {
        if (signatureRef.current) {
            signatureRef.current.clear();
            setFormState({ ...formState, signature: '' }); // Clear the signature in formState
        }
    };
    // signature end

    useEffect(() => {
        const requiredFields = [
            'patientName',
            'email',
            'phone',
            'age',
            'signature',
        ];
        const isFormValid = requiredFields.every((field) => formState[field as keyof typeof formState] !== '');
        setIsDisabled(!isFormValid);
    }, [formState]);

    const handleSelectChange = (e: SelectChangeEvent<string>) => {
        const { name, value } = e.target;
        setFormState({ ...formState, [name]: value })
    }
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormState({ ...formState, [name]: value })
    }
    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedMedicine(null);
    };

    const handleSaveMedicine = (medicine: Medicine) => {
        if (selectedMedicine) {
            setMedicines((prev) =>
                prev.map((med) => (med.id === selectedMedicine.id ? { ...medicine, id: med.id } : med))
            );
        } else {
            setMedicines([...medicines, { ...medicine, id: Date.now() }]);
        }
        setOpenModal(false);
    };

    const handleEditMedicine = (medicine: Medicine) => {
        setSelectedMedicine(medicine);
        setOpenModal(true);
    };

    const handleDeleteMedicine = (medicineId: number) => {
        setMedicines(medicines.filter((medicine) => medicine.id !== medicineId));
    };

    // api part start
    const fetchPrescription = async (data: { email: string, token: string | undefined, cid: string | undefined }) => {
        try {
            setLoading(true)
            const result = await getPrescriptionApi(data);
            if (result?.success) {
                setLoading(false)
                setPrescriptionData(result?.response)
                const PData = result?.response?.patientDetail as PatientDetail | null;
                const OtData = result?.response?.prescriptionDetail as PatientDetail | null;
                const DocData = result?.response?.doctorDetail;
                setDoctorData(DocData)
                if (PData) {
                    const _Age = PData?.age ? PData?.age : (PData?.dateofbirth && calculateAge(PData?.dateofbirth) || '')
                    setFormState({
                        ...formState,
                        patientName: PData?.name,
                        email: PData?.email,
                        phone: PData?.phone,
                        age: _Age ? _Age.toString() : '',
                        height: PData?.height,
                        weight: PData?.weight,
                        gender: PData?.gender,
                        location: PData?.location,
                        chiefComplaints: OtData?.chief_complaints ? OtData?.chief_complaints : '',
                        diagnosis: OtData?.diagnosis ? OtData?.diagnosis : '',
                        previousHistory: OtData?.relevant_points_from_history ? OtData?.relevant_points_from_history : '',
                        labFindings: OtData?.lab_findings ? OtData?.lab_findings : '',
                        suggestedInvestigations: OtData?.suggestedInvestigations ? OtData?.suggestedInvestigations : '',
                        specialInstructions: OtData?.specialInstructions ? OtData?.specialInstructions : '',
                        signature: DocData?.signature,
                    })
                }
            }
        } catch (error: any) {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (location?.state?.emailParam) {
            const emailParam = location?.state?.emailParam;
            const tokenParam = location?.state?.tokenParam;
            const cidParam = location?.state?.cidParam;
            fetchPrescription({ email: emailParam, token: tokenParam ? tokenParam : undefined, cid: cidParam ? cidParam : undefined });
        }
    }, [params, location.pathname])

    const handleSavePres = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const _medicineData = medicines?.map((item) => {
                return {
                    medicineId: item?.name?.id ? item?.name?.id : 0,
                    medicineName: item?.name?.medicineName !== 'Other' ? item?.name?.medicineName : item?.otherName,
                    frequency: item?.frequency,
                    duration: item?.duration,
                    drugForm: item?.drugForm ? item?.drugForm : '',
                    strength: item?.strength ? item?.strength : ''
                }
            })
            const cidParam = location?.state?.cidParam;
            const sendData = {
                "patientPrescriptionDetails": {
                    "previousHistory": formState?.previousHistory,
                    "diagnosis": formState?.diagnosis,
                    "labFindings": formState?.labFindings,
                    "suggestedInvestigations": formState?.suggestedInvestigations,
                    "specialInstructions": formState?.specialInstructions,
                    "chiefComplaints": formState?.chiefComplaints,
                    "patientId": prescriptionData?.patientDetail?.patientId ? prescriptionData?.patientDetail?.patientId : 0,
                    "appointmentId": 0,
                    "signature": prescriptionData?.doctorDetail?.signature ? prescriptionData?.doctorDetail?.signature : formState?.signature,
                    "patientName": formState?.patientName,
                    "patientEmail": formState?.email,
                    "phone": formState?.phone,
                    "age": formState?.age,
                    "height": formState?.height,
                    "weight": formState?.weight,
                    "gender": formState?.gender,
                    "location": formState?.location,
                    doctorEmail: DoctorData?.email,
                    callId: cidParam ? cidParam : 0
                },
                medicineDetails: _medicineData
            }
            setBtnLoading(true)
            const result = await savePrescriptionApi(sendData)
            if (result?.success) {
                setBtnLoading(false)
                toast.success(result?.message)
                setFormState({
                    patientName: '',
                    email: '',
                    phone: '',
                    age: '',
                    height: '',
                    weight: '',
                    gender: '',
                    location: '',
                    chiefComplaints: '',
                    diagnosis: '',
                    previousHistory: '',
                    labFindings: '',
                    suggestedInvestigations: '',
                    specialInstructions: '',
                    signature: '',
                })
                setMedicines([]);
            }
            else {
                toast.error(result?.message)
            }
        } catch (error: any) {
            setBtnLoading(false)
            toast.error(error?.message)
        }
    }
    // api part end



    if (loading) {
        return <Loader />
    }
    return (
        <div className={styles.presMain}>
            <CustomContainer>
                <form className={styles.form} onSubmit={handleSavePres}>
                    <section className={styles.userInfo}>
                        <div className={styles.heading}>
                            <h3>Patient Details</h3>
                        </div>
                        <div className='grid 2xl:grid-cols-4 lg:grid-cols-3 grid-cols-1 gap-6'>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Name <span className='errorMsg'>*</span></FormLabel>
                                <TextField
                                    type="text"
                                    className={`custumTextField profileInput`}
                                    name="patientName"
                                    required
                                    value={formState.patientName}
                                    onChange={handleChange}
                                    onKeyPress={(event) => {
                                        if (!/[a-zA-Z\s]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Email <span className='errorMsg'>*</span></FormLabel>
                                <TextField
                                    type="text"
                                    className={`custumTextField profileInput`}
                                    name="email"
                                    required
                                    value={formState.email}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Phone <span className='errorMsg'>*</span></FormLabel>
                                <TextField
                                    type="text"
                                    className={`custumTextField profileInput`}
                                    name="phone"
                                    required
                                    value={formState.phone}
                                    onChange={handleChange}
                                    inputProps={{
                                        inputMode: 'numeric',
                                        maxLength: 10,
                                    }}
                                    onKeyPress={handleKeyPress}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Age <span className='errorMsg'>*</span></FormLabel>
                                <TextField
                                    type="text"
                                    className={`custumTextField profileInput`}
                                    name="age"
                                    required
                                    value={formState.age}
                                    onChange={handleChange}
                                    inputProps={{
                                        inputMode: 'numeric',
                                        maxLength: 3,
                                    }}
                                    onKeyPress={handleKeyPress}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Gender <span className='errorMsg'>*</span></FormLabel>
                                <Select
                                    className={`custumTextField profileInput`}
                                    placeholder="Select gender"
                                    value={formState.gender}
                                    name='gender'
                                    required
                                    onChange={handleSelectChange}
                                >
                                    <MenuItem value="Male">Male</MenuItem>
                                    <MenuItem value="Female">Female</MenuItem>
                                    <MenuItem value="Others">Others</MenuItem>
                                </Select>
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Height</FormLabel>
                                <TextField
                                    type="text"
                                    className={`custumTextField profileInput`}
                                    name="height"
                                    value={formState.height}
                                    InputProps={{
                                        inputMode: 'numeric',
                                        endAdornment: <InputAdornment position="end">in cm</InputAdornment>,
                                    }}
                                    inputProps={{
                                        inputMode: 'numeric',
                                        maxLength: 3,
                                    }}
                                    onKeyPress={handleKeyPress}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Weight</FormLabel>
                                <TextField
                                    type="text"
                                    className={`custumTextField profileInput`}
                                    name="weight"
                                    value={formState.weight}
                                    onChange={handleChange}
                                    InputProps={{
                                        inputMode: 'numeric',
                                        endAdornment: <InputAdornment position="end">in kg</InputAdornment>,
                                    }}
                                    inputProps={{
                                        inputMode: 'numeric',
                                        maxLength: 3,
                                    }}
                                    onKeyPress={handleKeyPress}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Location</FormLabel>
                                <TextField
                                    type="text"
                                    className={`custumTextField profileInput`}
                                    name="location"
                                    value={formState.location}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        </div>
                    </section>
                    <section className={styles.DiagnosisSection}>
                        <div className={styles.heading}>
                            <h3>Diagnosis & Notes</h3>
                        </div>
                        <div className='grid md:grid-cols-2 grid-cols-1 gap-6'>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Chief Complaints</FormLabel>
                                <TextareaAutosize
                                    minRows={2}
                                    className={`custumTextField profileInput`}
                                    name='chiefComplaints'
                                    value={formState.chiefComplaints}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Diagnosis Or Provisional Diagnosis</FormLabel>
                                <TextareaAutosize
                                    minRows={2}
                                    className={`custumTextField profileInput`}
                                    name='diagnosis'
                                    value={formState?.diagnosis}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Relevant Points From History</FormLabel>
                                <TextareaAutosize
                                    minRows={2}
                                    className={`custumTextField profileInput`}
                                    name='previousHistory'
                                    value={formState?.previousHistory}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Examinations/Lab Findings</FormLabel>
                                <TextareaAutosize
                                    minRows={2}
                                    className={`custumTextField profileInput`}
                                    name='labFindings'
                                    value={formState?.labFindings}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Suggested Investigations</FormLabel>
                                <TextareaAutosize
                                    minRows={2}
                                    className={`custumTextField profileInput`}
                                    name='suggestedInvestigations'
                                    value={formState?.suggestedInvestigations}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Special Instructions</FormLabel>
                                <TextareaAutosize
                                    minRows={2}
                                    className={`custumTextField profileInput`}
                                    name='specialInstructions'
                                    value={formState?.specialInstructions}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        </div>
                    </section>
                    <section>
                        <div className={styles.heading}>
                            <h3>Medicine Details</h3>
                        </div>
                        <div className={styles.addmedicationBtn}>
                            <button type='button' className='fillButton' onClick={() => setOpenModal(true)}>+ Add Medication</button>
                        </div>
                        <div className='mt-6'>
                            <MedicineTable medicines={medicines} onEdit={handleEditMedicine} onDelete={handleDeleteMedicine} />
                        </div>
                    </section>
                    {
                        prescriptionData?.doctorDetail?.signature ? '' :
                            <section>
                                <div className={styles.heading}>
                                    <h3>Signature <span className='errorMsg'>*</span></h3>
                                </div>
                                <div className=''>
                                    <SignatureCanvas
                                        ref={signatureRef}
                                        penColor='green'
                                        canvasProps={{ className: 'custumSignature' }}
                                        onEnd={handleSignatureChange}
                                    />
                                </div>
                                {
                                    formState?.signature &&
                                    <div className='flex justify-end'>
                                        <button type="button" className='clearSignature' onClick={clearSignature}>
                                            Clear Signature
                                        </button>
                                    </div>
                                }
                            </section>
                    }
                    <div className='mt-10'>
                        <button type='submit' disabled={isDisabled || btnLoading} className='fillButton flexRowImp'> {btnLoading && <BtnLoader />} Generate Prescription</button>
                    </div>
                </form>
            </CustomContainer>
            <MedicineDetails
                openModal={openModal}
                btnLoading={btnLoading}
                handleCloseModal={handleCloseModal}
                saveMedicine={handleSaveMedicine}
                selectedMedicine={selectedMedicine}
            />
        </div>
    );
};

export default Prescription;
