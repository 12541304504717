import React from 'react'
import styles from "../../assets/styles/layout.module.css"
import CustomContainer from '../common/CustomContainer'
const Footer = () => {
  return (
    <footer className={styles.footer}>
      <CustomContainer>
        <ul className={styles.footerItems}>
          <li>Copyright © 2024 Akos | All Rights Reserved</li>
          <li>Email: info@akosmd.in</li>
          <li>Phone: +91-85954-61929</li>
        </ul>
      </CustomContainer>
    </footer>
  )
}

export default Footer