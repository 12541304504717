import React, { ChangeEvent, FC, FormEvent, useState } from 'react';
import CustomContainer from '../components/common/CustomContainer';
import styles from "../assets/styles/login.module.css";
import { FormGroup, FormLabel, TextField } from '@mui/material';
import { doctorLoginApi } from '../utils/services';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

interface LoginProps {
  onLogin: () => void;
}

const Login: FC<LoginProps> = ({ onLogin }) => {
  const [loginState, setLoginState] = useState({
    userId: '',
    password: ''
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLoginState({ ...loginState, [name]: value });
  };

  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (error) return;
      const sendData = {
        email: loginState.userId,
        password: loginState?.password
      }
      const result = await doctorLoginApi(sendData);
      if (result?.status_code === 200) {
        toast.success(result?.message)
        setLoginState({
          userId: "",
          password: ""
        })
        onLogin()
        navigate(`/`, {
          state: {
            emailParam: result?.result?.email
          }
        })

      }
    } catch (error: any) {
      console.log(error, "error")
      toast?.error(error?.data?.status_message ? error?.data?.status_message : error?.message)
    }
  };

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const phoneRegex = /^\d{10}$/;

  const validateUserId = (value: string) => {
    if (emailRegex.test(value) || phoneRegex.test(value)) {
      setError('');
    } else {
      setError('Invalid phone number or email');
    }
  };

  return (
    <div className={styles.presMain}>
      <CustomContainer>
        <div className={styles.loginMain}>
          <div className={styles.loginBox}>
            <div className={styles.loginHead}>
              <h3>Sign In</h3>
              <p>Welcome to AkosMD</p>
            </div>
            <form className={styles.form} onSubmit={handleLogin}>
              <div className='grid grid-cols-1 gap-6'>
                <FormGroup className="customFormGroup">
                  <FormLabel className="customFormLabel">Phone Number Or Email <span className='errorMsg'>*</span></FormLabel>
                  <TextField
                    type="text"
                    className={`custumTextField profileInput ${error ? 'error' : ''}`}
                    name="userId"
                    required
                    value={loginState?.userId}
                    onChange={handleChange}
                    InputProps={{
                      onBlur: (e) => {
                        validateUserId(e.target.value);
                      }
                    }}
                  />
                  {
                    error &&
                    <small className='errorMsgValid'>{error}</small>
                  }
                </FormGroup>
                <FormGroup className="customFormGroup">
                  <FormLabel className="customFormLabel">Password <span className='errorMsg'>*</span></FormLabel>
                  <TextField
                    type="password"
                    className={`custumTextField profileInput`}
                    name="password"
                    required
                    value={loginState?.password}
                    onChange={handleChange}
                  />
                </FormGroup>
                <button className='fillButton'>Proceed</button>
              </div>
            </form>
          </div>
        </div>
      </CustomContainer>
    </div>
  );
};

export default Login;