import { Autocomplete, Box, FormGroup, FormLabel, Modal, TextField } from '@mui/material';
import React, { useState, useEffect, ChangeEvent } from 'react';
import { CrossIcon, SearchIcon } from '../../utils/icons';
import BtnLoader from '../common/BtnLoader';
import { getMedicineApi } from '../../utils/services';
import { toast } from 'react-toastify';

interface MedicineDetailsProps {
    openModal: boolean;
    btnLoading: boolean;
    handleCloseModal: () => void;
    saveMedicine: (medicine: Medicine) => void;
    selectedMedicine: Medicine | null;
}

const MedicineDetails: React.FC<MedicineDetailsProps> = ({
    openModal,
    btnLoading,
    handleCloseModal,
    saveMedicine,
    selectedMedicine
}) => {
    const [searchInput, setSearchInput] = useState<string | any>('')
    const [medicineAllData, setMedicineAllData] = useState<NameTypes[] | []>([])
    const [medicine, setMedicine] = useState<Medicine>({
        id: selectedMedicine?.id,
        name: null,
        otherName: '',
        frequency: '',
        duration: '',
        instructions: '',
        drugForm: '',
        strength: '',
    });

    useEffect(() => {
        if (selectedMedicine) {
            setMedicine(selectedMedicine);
        } else {
            setMedicine({
                id: undefined,
                name: null,
                otherName: '',
                frequency: '',
                duration: '',
                instructions: '',
                drugForm: '',
                strength: '',
            });
        }
    }, [selectedMedicine]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setMedicine({ ...medicine, [name]: value });
    };

    const handleInputChange = (event: React.SyntheticEvent, newInputValue: string | { [key: string]: any; }) => {
        setSearchInput(newInputValue)
        setMedicine({ ...medicine })
    }


    const handleAutoComplete = (event: React.SyntheticEvent, value: NameTypes | null | string | { [key: string]: any }) => {
        setMedicine({ ...medicine, name: value as any })
    };

    const handleSave = () => {
        if(medicine?.name === null && medicine?.otherName ===""){
            return toast.error('Medicine name can not be blank')
        }
        saveMedicine(medicine);
        setMedicine({
            id: selectedMedicine?.id,
            name: null,
            frequency: '',
            duration: '',
            instructions: '',
            drugForm: '',
            strength: '',
        })
        setSearchInput('');
    };

    const fetchMedicineData = async (data: { search: string }) => {
        try {
            const result = await getMedicineApi(data);
            if (result?.success) {
                setMedicineAllData(result?.medicines)
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        const cleanFun = setTimeout(() => {
            fetchMedicineData({ search: searchInput || '' });
        }, 1000)
        return () => clearTimeout(cleanFun)
    }, [searchInput])

    return (
        <Modal open={openModal} onClose={handleCloseModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={{ width: 600 }} className={"ModalBoxStyle"}>
                <div className="modalHeader">
                    <h3>Medication Details</h3>
                    <CrossIcon onClick={handleCloseModal} />
                </div>
                <div className='modalBody'>
                    <form noValidate={false}>
                        <div className='grid grid-cols-1 gap-y-6'>
                            <FormGroup className="customFormGroup">
                                <FormLabel className={`customFormLabel`}>Type to search Medicine</FormLabel>
                                <Autocomplete
                                    freeSolo
                                    options={medicineAllData}
                                    className={'custumTextField profileInput'}
                                    value={medicine.name || ''}
                                    getOptionLabel={(option) => typeof option === 'string' ? option : option.medicineName}
                                    getOptionKey={(option) => typeof option === 'string' ? option : option.medicineName}
                                    onChange={handleAutoComplete}
                                    onInputChange={(event, newInputValue) => handleInputChange(event, newInputValue)}
                                    renderInput={(params) => (
                                        <div className="flex items-center">
                                            <TextField {...params} className="autocompleteInputStyle"  />
                                            <span className="searchIconOnAutocomplete">
                                                <SearchIcon />
                                            </span>
                                        </div>
                                    )}
                                />
                            </FormGroup>
                            {
                                medicine?.name?.medicineName === 'Other' &&
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">or Type Other Medicine Name here</FormLabel>
                                    <TextField
                                        type="text"
                                        className={`custumTextField profileInput`}
                                        name="otherName"
                                        placeholder='or Type Other Medicine Name here'
                                        value={medicine.otherName}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            }
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Drug Form</FormLabel>
                                <TextField
                                    type="text"
                                    className={`custumTextField profileInput`}
                                    name="drugForm"
                                    placeholder='Drug Form'
                                    value={medicine.drugForm}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Strength</FormLabel>
                                <TextField
                                    type="text"
                                    className={`custumTextField profileInput`}
                                    name="strength"
                                    placeholder='Strength'
                                    required
                                    value={medicine.strength}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Frequency</FormLabel>
                                <TextField
                                    type="text"
                                    className={`custumTextField profileInput`}
                                    name="frequency"
                                    placeholder='Frequency'
                                    value={medicine.frequency}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Duration</FormLabel>
                                <TextField
                                    type="text"
                                    className={`custumTextField profileInput`}
                                    name="duration"
                                    placeholder='Duration'
                                    value={medicine.duration}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Instructions</FormLabel>
                                <TextField
                                    type="text"
                                    className={`custumTextField profileInput`}
                                    name="instructions"
                                    placeholder='Instructions'
                                    value={medicine.instructions}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        </div>
                    </form>
                </div>
                <div className='modalFooter'>
                    <button className='outLineBtn' onClick={handleCloseModal}>Cancel</button>
                    <button className='fillButton' disabled={btnLoading} onClick={handleSave}>{btnLoading && <BtnLoader />} Save</button>
                </div>
            </Box>
        </Modal>
    );
};

export default MedicineDetails;
